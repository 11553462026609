<template>
  <div class="divBox">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name:'JoinUsIndex',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
.divBox{
  width: 100%;
  height: 100%;
}
</style>
